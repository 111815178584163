import React, { Component } from 'react';

export class Employees extends Component {
	render() {
		return (
			<div>
				<h1>Employees</h1>
				<p>Employees</p>
			</div>
	);
}
}