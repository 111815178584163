import React, { Component } from 'react';

export class Projects extends Component {
	render() {
		return (
			<div>
			<h1>Projects</h1>
			<p>Projects</p>
			</div>
	);
}
}