var graph = require("@microsoft/microsoft-graph-client");
var axios = require("axios");

function getGraphClient(accessToken) {
	// Initialize Graph client
	const client = graph.Client.init({
		// Use the provided access token to authenticate
		// requests
		authProvider: (done) => {
			done(null, accessToken.accessToken);
		}
	});

	alert("Request home.");
	axios.get("https://inadvancealmapi.azurewebsites.net/api/home",
		{
			headers:
			{
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
			}
		})
		.then((response) => {
			alert(response.data);
		});

	alert("Request value.");
	axios.get("https://localhost:44367/api/value",
			{
				headers:
				{
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'//,
					//'authorization': 'Bearer ' + accessToken.accessToken
				}
			})
		.then((response) => {
			alert(response.data);
		});

	return client;
}

export async function getUserDetails(accessToken) {
	const client = getGraphClient(accessToken);
	return await client.api("/me").get();
}