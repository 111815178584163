import React, { Component } from 'react';

export class Home extends Component {
    render() {
        return (
            <div>
                <h1>InAdvance Alm</h1>
                <p>WEBSITE</p>
            </div>
        );
    }
}
